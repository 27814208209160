// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-table-of-contents-tsx": () => import("./../../src/pages/table-of-contents.tsx" /* webpackChunkName: "component---src-pages-table-of-contents-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

